import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import store from './store'
Vue.prototype.$store = store

Vue.config.productionTip = false
import 'jquery' //引入
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
import './css/home.scss';
/*媒体查询*/
import './css/main.scss';

import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

import $ from 'jquery'
import http from './common/http.js'
Vue.prototype.$http = http
import Encrypt from './common/Encrypt.js'
Vue.prototype.$Encrypt = Encrypt
import utils from './common/utils.js'
Vue.prototype.$util = utils

Vue.prototype.getBannerFuc = function(type) {
	return new Promise((resolve, reject) => {
		var _this = this
		var params = {
			currentPage:1,
			pageSize:5,
			type:type,
		}
		http.post('frontEnd/index/getWheel', params).then(function(res) {
			//数据处理
			if (res.code == 200) {
				var obj = {}
				if(res.data.records.length>0){
					obj = res.data.records[0]
				}
				resolve(obj)
			} else {
				resolve(false)
			}
		})
	})
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
