<template>
	<!-- @contextmenu.prevent="" -->
	<div id="app" @contextmenu.prevent=""><router-view /></div>
</template>

<script>
export default {
	mounted() {
		if(sessionStorage.getItem("key_state")&&sessionStorage.getItem("token")){
			console.log("读取缓存登录")
			//存在缓存，赋值
			this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("key_state"))))
			if(!this.$store.state.isLogin){
				sessionStorage.setItem("token","")
			}
		}
	},
	methods: {
		
	}
};
</script>
<style lang="scss">
.ptop {
	padding-top: 100px !important;
}
.mauto{
	margin-left: auto !important;
}
.mt20{
	margin-top: 20px !important;
}
.mb20{
	margin-bottom: 20px !important;
}
.mb12{
	margin-bottom: 12px !important;
}
.m20{
	margin-left: 20px !important;
}
.ml20{
	margin-left: 20px !important;
}
.block5{
	width: 100%;
	height: 5px;
}
.block10{
	width: 100%;
	height: 10px;
}
.block20{
	width: 100%;
	height: 20px;
}
.block30{
	width: 100%;
	height: 30px;
}
.block40{
	width: 100%;
	height: 40px;
}
.block58{
	width: 100%;
	height: 60px;
}
.block60{
	width: 100%;
	height: 60px;
}
.wblock10{
	width: 10px;
	min-height: 1px;
	height: 100%;
}
.wblock20{
	width: 20px;
	min-height: 1px;
	height: 100%;
}
/deep/ #end{
	display: none;
}
img{
	max-width: 100%;
	// height: 100%;
	object-fit: cover;
	display: inline-block;
	border: none;
}
.afterstar::after {
	content: '*';
	color: #d81e06;
}
.block45{
	width: 100%;
	height: 45px;
}
.clamp {
	lines: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}
.clamp2 {
	lines: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
.clamp3 {
	lines: 3;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;
}
.clamp4 {
	lines: 4;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
}
.clamp5{
	lines: 5;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 5;
	overflow: hidden;
}
.clamp6{
	lines: 6;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 6;
	overflow: hidden;
}
#app {
	height: 100%;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// text-align: center;
	// color: #2c3e50;
}
.m10{
	margin-left: 10px;
}
.ql-indent-1:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 2em !important;
}
.ql-indent-2:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 4em !important;
}
.ql-indent-3:not(.ql-direction-rtl){
	padding-left: 0px !important;
	text-indent: 6em !important;
}
// ie隐藏滚动条样式代码：
 html {
    /*隐藏滚动条，当IE下溢出，仍然可以滚动*/
    -ms-overflow-style:none;
    /*火狐下隐藏滚动条*/
    overflow:-moz-scrollbars-none;
}
/*Chrome下隐藏滚动条，溢出可以透明滚动*/
html::-webkit-scrollbar{width:0px}
</style>
