import Vue from 'vue'
import store from '../store/index.js'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'index',
	redirect: '/home',
	component: () => import('@/views/index.vue'),
	children: [{
			path: '/home',
			name: 'home',
			component: () => import('@/views/home.vue')
		},
		{
			path: '/mine',
			name: 'mine',
			component: () => import('@/views/mine.vue')
		},
		{
			path: '/service',
			name: 'service',
			component: () => import('@/views/service.vue')
		},
		{
			path: '/deal',
			name: 'deal',
			component: () => import('@/views/deal.vue')
		},
		{
			path: '/dealDetail',
			name: 'dealDetail',
			component: () => import('@/views/dealDetail.vue')
		},
		{
			path: '/finance',
			name: 'finance',
			component: () => import('@/views/finance.vue')
		},
		{
			path: '/financeitem',
			name: 'financeitem',
			component: () => import('@/views/financeitem.vue')
		},
		{
			path: '/law',
			name: 'law',
			component: () => import('@/views/law.vue')
		},
		{
			path: '/lawitem',
			name: 'lawitem',
			component: () => import('@/views/lawitem.vue')
		},
		{
			path: '/lawHelp',
			name: 'lawHelp',
			component: () => import('@/views/lawHelp.vue')
		},
		{
			path: '/lawApply',
			name: 'lawApply',
			component: () => import('@/views/lawApply.vue')
		},
		{
			path: '/lawNameList',
			name: 'lawNameList',
			component: () => import('@/views/lawNameList.vue')
		},
		{
			path: '/information',
			name: 'information',
			component: () => import('@/views/information.vue')
		},
		{
			path: '/analysis',
			name: 'analysis',
			component: () => import('@/views/analysis.vue')
		},
		{
			path: '/analysis_sys',
			name: 'analysis_sys',
			component: () => import('@/views/analysis_sys.vue')
		},
		{
			path: '/analysis_sys_page',
			name: 'analysis_sys_page',
			component: () => import('@/views/analysis_sys_page.vue')
		},
		{
			path: '/analysisSysDetail',
			name: 'analysisSysDetail',
			component: () => import('@/views/analysisSysDetail.vue')
		},
		{
			path: '/infoDetail',
			name: 'infoDetail',
			component: () => import('@/views/infoDetail.vue')
		},
		{
			path: '/404',
			name: '404',
			component: () => import('@/views/404.vue')
		},
		{
			path: '/infoItem',
			name: 'infoItem',
			component: () => import('@/views/infoItem.vue')
		},
		{
			path: '/teamDetail',
			name: 'teamDetail',
			component: () => import('@/views/teamDetail.vue')
		},
		{
			path: '/zhichanCenter',
			name: 'zhichanCenter',
			component: () => import('@/views/zhichanCenter.vue')
		},
		{
			path: '/dealBefore',
			name: 'dealBefore',
			component: () => import('@/views/dealBefore.vue')
		},
		{
			path: '/projectDock',
			name: 'projectDock',
			component: () => import('@/views/projectDock.vue')
		},
		{
			path: '/proDockSchool',
			name: 'proDockSchool',
			component: () => import('@/views/proDockSchool.vue')
		},
		{
			path: '/proDockExpert',
			name: 'proDockExpert',
			component: () => import('@/views/proDockExpert.vue')
		},
		{
			path: '/proDockItem',
			name: 'proDockItem',
			component: () => import('@/views/proDockItem.vue')
		},
		{
			path: '/proDockTech',
			name: 'proDockTech',
			component: () => import('@/views/proDockTech.vue')
		},
		{
			path: '/proDockDetail',
			name: 'proDockDetail',
			component: () => import('@/views/proDockDetail.vue')
		},
		{
			path: '/activityCenter',
			name: 'activityCenter',
			component: () => import('@/views/activityCenter.vue')
		},
		{
			path: '/register',
			name: 'register',
			component: () => import('@/views/register.vue')
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/login.vue')
		},
		{
			path: '/changePassword',
			name: 'changePassword',
			component: () => import('@/views/changePassword.vue')
		},
		{
			path: '/trademark',
			name: 'trademark',
			component: () => import('@/views/trademark.vue')
		},
		{
			path: '/landmarkProducts',
			name: 'landmarkProducts',
			component: () => import('@/views/landmarkProducts.vue')
		},
		{
			path: '/landmarkDetail',
			name: 'landmarkDetail',
			component: () => import('@/views/landmarkDetail.vue')
		},
		{
			path: '/blankPage',
			name: 'blankPage',
			component: () => import('@/views/blankPage.vue')
		},
		{
			path: '/openLicense',
			name: 'openLicense',
			component: () => import('@/views/openLicense.vue')
		},
		{
			path: '/openLicenseItem',
			name: 'openLicenseItem',
			component: () => import('@/views/openLicenseItem.vue')
		},
		{
			path: '/openLicenseDetail',
			name: 'openLicenseDetail',
			component: () => import('@/views/openLicenseDetail.vue')
		},
	]
}]
const router = new VueRouter({
	mode: 'history',
	base: "",// /jiadian/ /zhongshan/ /ztianhe/
	routes
})

export default router
var LoginList = ["/","/home","/login","/register","/changePassword","/zhichanCenter","/mine","/service","/dealBefore","/law","/projectDock","/activityCenter","/information"]
var whiteList = ["/","/home","/zhichanCenter","/mine","/service","/dealBefore","/law","/projectDock","/activityCenter","/information"]
router.beforeEach(async (to, from, next) => {
	// document.title = getPageTitle(to.meta.title)
	console.log(to)
	if(whiteList.indexOf(to.path)>-1){
		//console.log("SET_CURPAGE")
		store.commit('SET_CURPAGE', to.name)
	}
	if(to.matched.length>0){
		next()
		// if(LoginList.indexOf(to.path)<0){
		// 	if(sessionStorage.getItem("token")){
		// 		next()
		// 	}else{
		// 		next(`/login?redirect=${to.path}`)
		// 	}
		// }else{
		// 	next()
		// }
	}else{
		next('/404') // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
	}
})
// 切换页面滚动回顶部
router.afterEach((to,from,next) => {
	// console.log("11322")
    window.scrollTo(0,0);
    // 或
    // window.scroll(0, 0);
});
// 处理高版本连续跳转的错误
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}
