import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//为了方便测试，此处用vuex做全局数据
const store = new Vuex.Store({
	state: {
		userInfo: {},
		isLogin: false,//是否登录
		remark: '',
		curpage:"home",
		pvNum:0,//浏览量
		sysRole:"huaxue",// huaxue  jiadian zhongshan ztianhe
	},
	mutations: {
		SET_CURPAGE(state, curpage) {
			console.log(curpage)
			state.curpage = curpage
		},
		SET_REMARK(state, remark) {
			state.remark = remark
		},
		SET_USERINFO(state, userInfo) {
			console.log(userInfo)
			state.userInfo = userInfo
		},
		SET_ISLOGIN(state, isLogin) {
			console.log(isLogin)
			state.isLogin = isLogin
		},
		SET_pvNum(state, pvNum) {
			console.log(pvNum)
			state.pvNum = pvNum
		}
	}
})

export default store
