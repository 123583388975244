//引入加密的方法
// import JSEncrypt from './jsencrypt.js';
import JSEncrypt from './logonjsencrypt.js';
var PublicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDusZafDKzsQYt2g+RzsTFZIO5y9UiKqCg3ExMXVWSMqkl/dVsGPQir5Ne/OzdbFzUP8oB1rtvkL3BAtoJMwhMNXJxqOgVW8cBF+neXY0ydbUHX5WXlEXaxXwObQ6N6sGNBCA0Lqkk++wbbmruX+K/Ypr5JcSX+tSVGT0ioOFsYLwIDAQAB";
var privateKey = "MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBAO6xlp8MrOxBi3aD5HOxMVkg7nL1SIqoKDcTExdVZIyqSX91WwY9CKvk1787N1sXNQ/ygHWu2+QvcEC2gkzCEw1cnGo6BVbxwEX6d5djTJ1tQdflZeURdrFfA5tDo3qwY0EIDQuqST77Btuau5f4r9imvklxJf61JUZPSKg4WxgvAgMBAAECgYAH+A3bSYUMbjPFxSl/Y08Odrb/R4kgd/S/wfdFNCijOZwykSx0zNzZQE6jp8p1VBUFb0cypp/Ywmyw/GblQSV5oDguxqien5+s04mLqXzVrAV/o5jimxc4hb8B4cGAQUUX4N/AgOCLQGAGNqByJOdhmMzui7c2pRI60hMgpCOsYQJBAPnIIRgwBaFhvF4C2l7CtvQwZkzUeryv6fO81HkCaW6Kvsr2PE16wDY717Rz5EKo9wMIlne0iAQcvb9ShPVY8UUCQQD0ostfrlKwgoV9wg2gy4UG+K3XODx4IZ4fC9nj+UkYCQwSE19vZ4S+yy4tkvYCmgLYxEipNUhTVF0Ahfda1wjjAkEA3GKX0J+OWDXD3a7mvXVPt2R83o0bPiB4a3XQgnR+UepZLPrVegc0UKW8TJt0atxqFWMeX8AJwpcZVBWa+l7fgQJBAPJikirdJ+TnjahKqwIUZFvB7Ux+1bsZY/fS4DK2/isoW6NVOovA/4+i9MAHCmUNf6CS6oNs3ZpizjsEgU6qHI0CQBQQnmElXe781yztNTIAKTlx9dRHJOjMXBLUA647Kdh3d0oqO35oAJKS4kqUaJCDh8xVzP1CEnBcrtgm9y/AK6U=";
var enjsepbj = null;
var dejsepbj = null;

/**
 * function: 加密参数值
 * @params 请求参数
 */
function encrypt(params) {
	// enjsepbj.encrypt(JSON.stringify(params));
	if(params&&params!=""&&params!=null){
		var str = JSON.stringify(params)
		// console.log(str)
		// console.log(str.length)
		//把参数字符串按长度截取加密成数组，再变成字符串传输
		var strArr = [];
		var n = 50;
		for (var i = 0, l = str.length; i < l/n; i++) {
		  var a = str.slice(n*i, n*(i+1));
		  var jiami = enjsepbj.encrypt(a) //enjsepbj.encryptLong(a)
		  strArr.push(jiami);
		  // console.log(jiami)
		  // var jiemi = enjsepbj.decryptLong(jiami)
		  // console.log(jiemi)
		}
		// console.log(strArr)
		// console.log(JSON.stringify(strArr))
		return JSON.stringify(strArr)
	}else{
		return params
	}
}

/**
 * function: 解密返回值
 * @params 请求参数
 */
function decrypt(jsondata) {
	console.log(jsondata.data)
	console.log(dejsepbj.decrypt(jsondata.data))
	jsondata["data"] = dejsepbj.decrypt(jsondata.data)
	console.log(jsondata)
	return jsondata;
}

/**
 * function: 初始化加密数据
 */
function initJSEncrypt() {
	//RSA非对称加密
	enjsepbj = new JSEncrypt()//创建加密
	enjsepbj.setPublicKey(PublicKey)//配置公钥，一般都为后端传输过来的
	// dejsepbj = new JSEncrypt()//创建解密
	// dejsepbj.setPrivateKey(privateKey)
	// var obj = {
	// 	userUuid:"woaosaidajsdad",
	// 	name:"我的名字",
	// 	password:"我的密码"
	// }
	// var miwen = enjsepbj.encrypt(JSON.stringify(obj))
	// console.log(miwen)  //false or 加密数据
	// //设置私钥
	// enjsepbj.setPrivateKey(privateKey)
	// console.log(JSON.parse(enjsepbj.decrypt(miwen)))  //false or 加密数据
}

export default {
	encrypt,
	decrypt,
	initJSEncrypt,
}
